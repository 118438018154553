import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_REGIONS_REQUEST: null,
    GET_REGIONS_SUCCESS: null,
    GET_REGIONS_FAILURE: null,
});

export const {
    getRegionsRequest,
    getRegionsSuccess,
    getRegionsFailure,
} = createActions(
    constants.GET_REGIONS_REQUEST,
    constants.GET_REGIONS_SUCCESS,
    constants.GET_REGIONS_FAILURE,
);
