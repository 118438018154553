export const resetPasswordUrl = 'https://selfservice.welbeckhealth.com/authorization.do?logout=1&logoutFromSSO=true';
export const registerUrl = 'https://portal.onewelbeck.com/';

export const regions = [
    {
        id: 1,
        title: 'London',
    },
    {
        id: 2,
        title: 'Oxford',
    },
    {
        id: 3,
        title: 'Edinburgh',
    },
];

const digestiveHealth = require('../../assets/images/service-digestive.jpg');
const earNoseAndThroat = require('../../assets/images/ear-nose-throat.jpg');
const imagingAndDiagnotics = require('../../assets/images/imaging-diagnotics.jpg');
const neurology = require('../../assets/images/neurology.jpg');
const endoscopy = require('../../assets/images/endoscopy.jpg');
const endocrinology = require('../../assets/images/endoclinology.jpg');
const heartHealth = require('../../assets/images/heart-health.jpg');
const lungHealth = require('../../assets/images/lung-health.jpg');
const orthopaedics = require('../../assets/images/orthopaedics.jpg');
const womensHealth = require('../../assets/images/womens-health.jpg');
const skinHealthAndAllergy = require('../../assets/images/skin-health-allergy.jpg');

export const imageList = [
    { imageSrc: digestiveHealth, specialityId: 1 },
    { imageSrc: earNoseAndThroat, specialityId: 4 },
    { imageSrc: imagingAndDiagnotics, specialityId: 67 },
    { imageSrc: neurology, specialityId: 45 },
    { imageSrc: endoscopy, specialityId: 2 },
    { imageSrc: endocrinology, specialityId: 24 },
    { imageSrc: heartHealth, specialityId: 33 },
    { imageSrc: lungHealth, specialityId: 38 },
    { imageSrc: orthopaedics, specialityId: 47 },
    { imageSrc: womensHealth, specialityId: 55 },
    { imageSrc: skinHealthAndAllergy, specialityId: 37 }];

export const GUEST_USER_TYPE_ID = 1;
export const GP_USER_TYPE_ID = 2;
// Booking information levels
export const PATIENT_LEVEL = 1;
export const SERVICE_LEVEL = 2;
export const MODALITY_LEVEL = 3;

export const AVAILABALITY_INFO_ID = 8;
export const DATA_TYPE_BOOL = 'Yes / No';
export const DATA_TYPE_DATETIME = 'Datetime';
export const DATA_TYPE_DOCUMENT = 'Document';

export const IGNORED_FORM_ITEMS = ['serviceCard', 'subtitle', 'separator'];

// User Ordering Permissions
export const ORDER_METHOD_EMAIL = 1;
export const ORDER_METHOD_ORDER = 2;
export const ORDER_METHOD_BOOK = 3;

export const cardMockData = [
    {
        clinicianId: 1,
        profileId: 1,
        firstName: 'Mr Joseph',
        surname: 'Manjaly',
        fullName: 'Mr Joseph Manjaly',
        title: '',
        color: '#8e2c48',
        specialityId: 2,
        specialityName: 'Ear, Nose & Throat',
        subFields: [
            { id: 1, fieldTitle: 'Rhinoplasty' },
            { id: 2, fieldTitle: 'Sinusitis' },
            { id: 3, fieldTitle: 'Ear Infections' },
            { id: 4, fieldTitle: 'Perforated Eardrum' },
        ],
        onPress: () => {
            console.log('Item 1 pressed');
        },
        imageUrl: earNoseAndThroat,
        availability: 'Today',
        portalCentreId: '',
    },
    {
        clinicianId: 2,
        profileId: 2,
        firstName: 'Mr Chadwan',
        surname: 'Al-Yaghchi',
        fullName: 'Mr Chadwan Al-Yaghchi',
        title: 'Item 2',
        color: '#c12121',
        specialityId: 6,
        specialityName: 'Endocrinology',
        subFields: [
            { id: 1, fieldTitle: 'Thyroid Disorders' },
        ],
        onPress: () => {
            console.log('Item 2 pressed');
        },
        imageUrl: endocrinology,
        availability: 'Tomorrow',
        portalCentreId: 'Oxford',
    },
    {
        clinicianId: 3,
        profileId: 3,
        firstName: 'Dr Mark',
        surname: 'Vanderpump',
        fullName: 'Dr Mark Vanderpump',
        specialityId: 7,
        title: 'Item 3',
        color: '#5c2244',
        specialityName: 'Heart Health',
        subFields: null,
        onPress: () => {
            console.log('Item 3 pressed');
        },
        imageUrl: heartHealth,
        availability: null,
    },
    {
        clinicianId: 4,
        profileId: 4,
        firstName: 'Dr Lisa',
        surname: 'Das',
        fullName: 'Dr Lisa Das',
        specialityId: 8,
        title: 'Item 4',
        color: '#ff6f61',
        specialityName: 'Lung Health',
        subFields: [
            { id: 1, fieldTitle: 'Irritable Bowel Syndrome' },
            { id: 2, fieldTitle: 'Cancer' },
        ],
        onPress: () => {
            console.log('Item 2 pressed');
        },
        imageUrl: lungHealth,
        availability: 'Today',
    },
    {
        clinicianId: 5,
        profileId: 5,
        firstName: 'Mr Chadwan',
        surname: 'Al-Yaghchi',
        fullName: 'Mr Chadwan Al-Yaghchi',
        title: 'Item 2',
        color: '#c12121',
        specialityId: 6,
        specialityName: 'Endocrinology',
        subFields: [
            { id: 1, fieldTitle: 'Thyroid Disorders' },
        ],
        onPress: () => {
            console.log('Item 2 pressed');
        },
        imageUrl: endocrinology,
        availability: 'Tomorrow',
        portalCentreId: 'Oxford',
    },
    {
        clinicianId: 6,
        profileId: 6,
        firstName: 'Dr Mark',
        surname: 'Vanderpump',
        fullName: 'Dr Mark Vanderpump',
        specialityId: 7,
        title: 'Item 3',
        color: '#5c2244',
        specialityName: 'Heart Health',
        subFields: null,
        onPress: () => {
            console.log('Item 3 pressed');
        },
        imageUrl: heartHealth,
        availability: null,
    },
    {
        clinicianId: 7,
        profileId: 7,
        firstName: 'Dr Lisa',
        surname: 'Das',
        fullName: 'Dr Lisa Das',
        specialityId: 8,
        title: 'Item 4',
        color: '#ff6f61',
        specialityName: 'Lung Health',
        subFields: [
            { id: 1, fieldTitle: 'Irritable Bowel Syndrome' },
            { id: 2, fieldTitle: 'Cancer' },
        ],
        onPress: () => {
            console.log('Item 2 pressed');
        },
        imageUrl: lungHealth,
        availability: 'Today',
    },
];

export const serviceDetailsMock = [
    {
        specialityId: 1,
        specialityName: 'Digestive Health',
        color: '#a1a466',
        imageUrl: digestiveHealth,
    },
    {
        specialityId: 4,
        specialityName: 'Ear, Nose & Throat',
        color: '#8e2c48',
        imageUrl: earNoseAndThroat,
    },
    {
        specialityId: 67,
        specialityName: 'Imaging & Diagnotics',
        color: '#98d9ff',
        imageUrl: imagingAndDiagnotics,
    },
    {
        specialityId: 45,
        specialityName: 'Neurology',
        color: '#92b8ff',
        imageUrl: neurology,
    },
    {
        specialityId: 2,
        specialityName: 'Endoscopy',
        color: '#d86e2c',
    },
    {
        specialityId: 24,
        specialityName: 'Endocrinology',
        color: '#c12121',
    },
    {
        specialityId: 33,
        specialityName: 'Heart Health',
        color: '#5c2244',
    },
    {
        specialityId: 38,
        specialityName: 'Lung Health',
        color: '#ff6f61',
        imageUrl: lungHealth,
    },
    {
        specialityId: 47,
        specialityName: 'Orthopaedics',
        color: '#004438',
    },
    {
        specialityId: 55,
        specialityName: 'Women\'s Health',
        color: '#beb2d5',
    },
    {
        specialityId: 37,
        specialityName: 'Skin Health & Allergy',
        color: '#a2e8db',
    },
];

// hardcoded service hours
export const serviceOpeningHours = [
    'Mon - 08:00 - 20:00',
    'Tue - 08:00 - 20:00',
    'Wed - 08:00 - 20:00',
    'Thu - 08:00 - 20:00',
    'Fri - 08:00 - 20:00',
    'Sat - 08:00 - 20:00',
    'Sun - 08:00 - 20:00',
];

export const LIMITED_AVAILABILITY_THRESHOLD = 12;

export const DEFAULT_MOBILE_NUMBER = '02036532001';

export const BOOKING_STATUS_AVAILABLE = 0;
export const BOOKING_STATUS_SUCCESS = 1;
