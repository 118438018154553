import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    TOGGLE_FAVOURITE: null,
});

export const {
    toggleFavourite,
} = createActions(
    constants.TOGGLE_FAVOURITE,
);
