// eslint-disable-next-line no-unused-vars
import { put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApi } from '../utils/api';

import {
    getCentreProfileSuccess,
    getCentreProfileFailure,
} from './centreAction';

const {
    GET_CENTRE_PROFILE_REQUEST,
} = require('./centreAction').constants;

function* getCentreProfile({ payload }) {
    const { centreId } = payload;

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `centres/getCentreProfile?centreId=${centreId}`,
        });

        yield put(getCentreProfileSuccess(response?.data));
    } catch (e) {
        message.error(e.response?.data?.message || 'Something went wrong');
        yield put(getCentreProfileFailure(e.response?.data?.message || 'Something went wrong'));
    }
}

export default function* filterSagas() {
    yield* [
        takeEvery(GET_CENTRE_PROFILE_REQUEST, getCentreProfile)];
}
