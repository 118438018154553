import { useDispatch, useSelector } from 'react-redux';
import { postBookingRequest } from '../bookings/bookingsActions';

/**
 * This hook handles converting the extra information fields and user responses into a request body for the postAPIrequest endpoint
 * It traverses the fieldmap for each information level, and groups requests by modality before combining with the user responses.
 * It returns a function which when called will dispatch the postBookingRequest action.
 * @returns {object}
 */
function useSubmitBooking() {
    const dispatch = useDispatch();
    const { patientBookingsDetails, modalityData, serviceIds } = useSelector(state => state.bookings);
    const { selectedSlots } = useSelector(state => state.availability);

    /**
     * Creates a ScheduleInfo object to add to the request when the slot can be booked directly
     * @param {number} serviceId
     * @returns
     */
    const getScheduleInfo = (serviceId) => {
        const selectedSlot = selectedSlots?.[serviceId] || null;

        if (!selectedSlot) {
            return null;
        }

        if (selectedSlot && selectedSlot.scheduleInfo) {
            const { resourceType, resourceId, resourceName, date, startTime } = selectedSlot;

            return {
                ResourceType: Number(resourceType),
                ResourceID: resourceId.toString(),
                ResourceName: resourceName,
                Date: date,
                StartTime: `${startTime}:00`,
            };
        }

        return null;
    };

    const makeModalityData = (data) => ({
        Modality: data.map((modality) => {
            const portalServiceId = serviceIds[modality.modalityId].join(',') || '';
            return {
                ModalityID: modality.modalityId,
                ModalityName: modality.modalityName,
                ScheduleInfo: getScheduleInfo(portalServiceId),
                PortalServices: modality.portalServices.map((service) => ({
                    CCOrderID: service.ccOrderId,
                    PortalServiceRequestID: service.portalServiceRequestId,
                    PortalServiceRequestName: service.portalServiceRequestName,
                    CCServiceRequestID: service.ccServiceRequestId,
                    CCProcedureID: service.ccProcedureId,
                    DateAdded: service.dateTimeAdded.split('T')[0],
                })),
            };
        }),
    });

    const makeBookingRequestPayload = () => {
        const modalityDataResponse = makeModalityData(modalityData);

        return {
            MessageID: patientBookingsDetails.messageId,
            PatientID: patientBookingsDetails.patientId,
            SecretaryEmail: patientBookingsDetails?.secretaryEmail || null,
            ...modalityDataResponse,
        };
    };

    const submitBookingRequest = () => {
        const bookingRequests = makeBookingRequestPayload();

        dispatch(postBookingRequest({ bookingRequests }));
    };

    return {
        submitBookingRequest,
    };
}

export default useSubmitBooking;
