import { isUndefined } from 'underscore';

export function trimString(string) {
    if (!string) {
        return '';
    }

    return string.trim().replace(/[\n\r]/g, '');
}

export const test = () => {
    console.log('test');
};

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
const FILTER_DATE_FORMAT = 'DD MMM';
const REDUX_DATE_FORMAT = 'YYYY-MM-DD';
const DATE_PICKER_FORMAT = 'YYYY-MM-DD';
const SUBMIT_DATE_FORMAT = 'h:mm a, dddd Do MMMM YYYY';
const FULL_DATE_FORMAT = 'dddd Do MMMM';

export {
    // Constants
    DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    FILTER_DATE_FORMAT,
    REDUX_DATE_FORMAT,
    DATE_PICKER_FORMAT,
    SUBMIT_DATE_FORMAT,
    FULL_DATE_FORMAT,

};

/**
 * takes an object of param=value pairs, and returns a param string of the format param1=value1&param2=value2...
 *
 * @param {object} params
 * @returns string
 */
function makeParams(params) {
    return Object.entries(params)
        .map(([key, value]) => (!isUndefined(value) ? `${key}=${value}` : null))
        .filter(p => p)
        .join('&');
}

/**
 * Takes an endpoint URL and a params object and returns a query string
 * @param {string} url Endpoint URL
 * @param {Object} paramsObject Params object of the form { param1: value1, param2: value2 }
 * @returns Query string
 */
export const makeUrl = (url, paramsObject) => {
    const params = makeParams(paramsObject);
    return `${url}${params.length > 0 ? `?${params}` : ''}`;
};

export function generateRandomId(length = 32) {
    let result = '';
    const characters = 'abcdef0123456789';

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}
