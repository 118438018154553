import React, { useCallback } from 'react';

import './card.scss';

import { trimString } from '../../core/utils/helpers';
import Tag from '../Tag';
import fallbackImage from '../../assets/images/fallback.png';
import FavouriteButton from '../FavouriteButton';
import AvailabilityFlag from '../AvailabilityFlag';

const Card = ({ profileId, title, specialityName, subFields, onPress, imageSrc, availability, color, compact, onFavourite, favourite, booking }) => {
    const handleBodyPress = useCallback(() => {
        onPress();
        console.log({
            profileId,
            specialityName,
            subFields,
            availability,
            color,
            compact,
            onFavourite,
            favourite,
            booking,
        });
    }, [onPress]);

    const handleFavPress = useCallback(() => {
        onFavourite(profileId);
    }, [onFavourite, profileId]);

    const renderMainCard = () => (
        <div className="card-container">
            <div style={{ flex: 1 }}>
                <div key={title} onClick={() => handleBodyPress()}>
                    <div className="image-container">
                        <img
                            src={imageSrc || fallbackImage}
                            alt={title} />
                        {availability ? <AvailabilityFlag availability={availability} /> : null}
                    </div>
                    <div className="card-content">
                        <span className="card-title">{trimString(title)}</span>
                        <Tag specialityName={specialityName} subFields={subFields} color={color} />
                    </div>
                </div>
                <div className="favourite-button" onClick={() => handleFavPress()}>
                    <FavouriteButton favourite={favourite} />
                </div>
            </div>
        </div>
    );

    return renderMainCard();
};

Card.propTypes = {
    // PropTypes for your props
};

Card.defaultProps = {
    // Default props
};

export default React.memo(Card);
