import React, { useEffect } from 'react';
import { Button, Spin, message, Modal } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { useNavigate } from 'react-router';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './bookingResult.scss';
import { cleanUpBookingData, cleanUpBookingResponse } from '../../core/bookings/bookingsActions';
import { getCentreProfileRequest } from '../../core/centre/centreAction';
import { cleanUpAvailabilityData } from '../../core/availability/availabilityActions';

const BookingResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { bookingResponses, isFetching, error } = useSelector(state => state?.bookings);
    const hasFailureStatus = bookingResponses?.modality?.some((item) => item.portalServices.some((service) => service.serviceStatus !== 'Success'));
    const failedServices = bookingResponses?.modality?.flatMap(item => item.portalServices.filter(service => service.serviceStatus !== 'Success'));
    const failureServiceNames = failedServices?.map(service => service.portalServiceRequestName).join(', ');
    const { centreProfile } = useSelector(state => state?.centre);

    const contactWelbeck = () => {
        if (centreProfile?.defaultNumber) {
            // eslint-disable-next-line no-undef
            const isMobileDevice = /Mobi|Android|iPhone/i.test(navigator.userAgent);
            if (isMobileDevice) {
                // eslint-disable-next-line no-undef
                window.location.href = `tel:${centreProfile.defaultNumber}`;
            } else {
                Modal.info({
                    title: 'Contact OneWelbeck',
                    closable: true,
                    footer: null,
                    content: (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <div style={{ flex: 1 }}>
                                <p className="contact-number">Contact number: <strong>{centreProfile.defaultNumber}</strong></p>
                                <Button
                                    type="primary"
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        // eslint-disable-next-line no-undef
                                        navigator.clipboard.writeText(centreProfile.defaultNumber);
                                        message.success('Number copied to clipboard!');
                                    }}>
                                    Copy
                                </Button>
                            </div>
                        </div>
                    ),
                });
            }
        } else {
            message.error('Contact number not available');
        }
    };

    useEffect(() => {
        dispatch(getCentreProfileRequest({ centreId: 1 }));
    }, []);

    useEffect(() => {
        if (!isEmpty(bookingResponses)) {
            // eslint-disable-next-line no-undef
            window.history.pushState(null, null, window.location.pathname);
            // eslint-disable-next-line no-undef
            window.addEventListener('popstate', () => {
                // eslint-disable-next-line no-undef
                window.history.pushState(null, null, window.location.pathname);
                // eslint-disable-next-line no-undef
                window.history.go(1);
            });
            if (!hasFailureStatus) {
                dispatch(cleanUpBookingData());
                dispatch(cleanUpAvailabilityData());
            }
        }
    }, [bookingResponses]);

    const handleRetry = () => {
        dispatch(cleanUpBookingResponse());
        navigate('/appointment');
    };

    return (
        <div className="booking-result-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            {isFetching ? <Spin size="large" /> : (
                <div className="result-container">
                    {!hasFailureStatus && !error && (
                        <>
                            <CheckCircleOutlined className="result-icon success" />
                            <h1 className="result-title">Booking Successful</h1>
                            <p className="result-message">
                                All appointments have successfully been booked at your requested times.
                            </p>
                        </>
                    )}
                    {hasFailureStatus && (
                        <>
                            <ExclamationCircleOutlined className="result-icon error" />
                            <h1 className="result-title">Booking Unsuccessful</h1>
                            <p className="result-message">
                                We&apos;re sorry, there was an issue when completing one of the appointments in your booking.
                            </p>
                            <p className="appointment-details">
                                <strong>{failureServiceNames}</strong>
                            </p>
                            <p className="result-message">
                                OneWelbeck will be in touch to rearrange the appointment with you.
                            </p>
                            <Button type="primary" className="retry-button" onClick={handleRetry}>
                                Try Again
                            </Button>
                            <Button type="default" className="contact-button" onClick={contactWelbeck}>
                                Contact OneWelbeck
                            </Button>
                        </>
                    )}
                    {error && (
                        <>
                            <ExclamationCircleOutlined className="result-icon error" />
                            <h1 className="result-title">Booking Unsuccessful</h1>
                            <p className="result-message">
                                We&apos;re sorry, there was an issue when completing one of the appointments in your booking.
                            </p>
                            <p className="result-message">
                                OneWelbeck will be in touch to rearrange the appointment with you.
                            </p>
                            <Button type="default" className="contact-button" onClick={contactWelbeck}>
                                Contact OneWelbeck
                            </Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default BookingResult;
