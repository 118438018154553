import React, { useState } from 'react';
import { Button, Input, Form, Select } from 'antd';
import './paymentMethod.scss';

const PaymentMethod = () => {
    const { Option } = Select;
    const [method, setMethod] = useState(null);
    const [insuranceProvider, setInsuranceProvider] = useState(null);

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleInsuranceProviderChange = (value) => {
        setInsuranceProvider(value);
    };
    return (
        <div className="payment-method">
            <h3>Choose a Payment Method</h3>
            <Button className="apple-pay-button" onClick={() => setMethod('applePay')}>Pay with  Apple Pay</Button>
            <Button className="credit-card-button" onClick={() => setMethod('creditCard')}>Pay with Credit / Debit Card</Button>

            {method === 'creditCard' && (
                <Form
                    name="credit-card-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="credit-card-form">
                    <Form.Item
                        name="cardHolderName"
                        rules={[{ required: true, message: 'Please enter the card holder name' }]}>
                        <Input placeholder="Card Holder Name" />
                    </Form.Item>
                    <Form.Item
                        name="cardNumber"
                        rules={[{ required: true, message: 'Please enter the card number' }]}>
                        <Input placeholder="Card Number" />
                    </Form.Item>
                    <div className="expiry-cvv">
                        <Form.Item
                            name="expiryDate"
                            rules={[{ required: true, message: 'Please enter the expiry date' }]}>
                            <Input placeholder="Expiry Date" />
                        </Form.Item>
                        <Form.Item
                            name="cvv"
                            rules={[{ required: true, message: 'Please enter the CVV' }]}>
                            <Input placeholder="CVV" />
                        </Form.Item>
                    </div>
                    <Form.Item
                        name="postcode"
                        rules={[{ required: true, message: 'Please enter the postcode' }]}>
                        <Input placeholder="Postcode" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Confirm & Pay
                        </Button>
                    </Form.Item>
                </Form>
            )}
            <Button className="insurance-button" onClick={() => setMethod('insurance')}>Insurance / Other Payer</Button>
            {method === 'insurance' && (
                <Form
                    name="insurance-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="insurance-form">
                    <Form.Item
                        name="insuranceProvider"
                        rules={[{ required: true, message: 'Please select an insurance provider' }]}>
                        <Select
                            placeholder="Select Insurance Provider / Payer"
                            onChange={handleInsuranceProviderChange}>
                            <Option value="provider1">Provider 1</Option>
                            <Option value="provider2">Provider 2</Option>
                            <Option value="other">Other</Option>
                        </Select>
                    </Form.Item>
                    {insuranceProvider === 'other' && (
                        <>
                            <h3>Please Enter Details</h3>
                            <Form.Item
                                name="insuranceDetails"
                                rules={[{ required: true, message: 'Please enter the details' }]}>
                                <Input.TextArea
                                    placeholder="e.g. partner is paying, insurer not in list"
                                    autoSize={{ minRows: 5, maxRows: 8 }} />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Confirm
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default PaymentMethod;
