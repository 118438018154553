import React, { useState } from 'react';
import { Row, Col, Layout } from 'antd';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Menu from '../Menu';
import logoIcon from '../../../assets/images/welbeck-logo.png';
import locationIcon from '../../../assets/images/location-icon.png';
import searchIcon from '../../../assets/images/search-icon.png';
import basketIcon from '../../../assets/images/basket-icon.png';
import LocationModal from '../../Modals/LocationModal';
import './header.css';

const { APP_NAME } = require('../../../core/constants').default;

const Header = (props) => {
    const {
        toolbar, menu, backLink, title, menuPlacement, menuWidth, floating,
        beforeHeader, afterHeader,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { userLocation } = useSelector(state => state.user);

    const showModal = () => {
        setIsModalVisible(true);
    };

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row justify="space-between" style={{ height: 'inherit', alignItems: 'center', width: '100%' }}>
                    <Row style={{ height: 'inherit', alignItems: 'center', justifyContent: 'space-between', width: ' 100%' }}>
                        <Col>
                            {menu.length > 0 ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : <img src={logoIcon} alt={`${APP_NAME}`} className="logo" />}
                        </Col>
                        <Col>
                            {backLink || userLocation ? <div className="location-container"><img src={locationIcon} alt="location" className="location-icon" /><span>{userLocation?.title}</span></div> : null}
                        </Col>
                        <Col>
                            <span onClick={showModal} className="change-location-link">Change Location</span>
                        </Col>

                        {title
                            ? (
                                <Col>
                                    <h3 className="header__title h-4">{title}</h3>
                                </Col>
                            )
                            : (
                                <Col xs={14} md={16} className="input-container">
                                    <img src={searchIcon} alt="Search" className="search-icon" />
                                    <input type="text" placeholder="Search by name, speciality, expertise etc." className="custom-input" />
                                </Col>
                            )}
                        { console.log(toolbar) }
                        {/*
                    // uncomment this if you want to use toolbar
                    <Col>
                        <div className="d-flex justify-content-end">
                            {toolbar.map((button, buttonIndex) => (
                                <Button
                                    key={button.id || buttonIndex}
                                    size={button.size || 'default'}
                                    className="toolbar-button"
                                    href={button.link || '#'}
                                    onClick={button.onClick || null}>

                                    <span>{button.label}</span>
                                </Button>
                            ))}
                        </div>
                    </Col> */
                        }
                        <Col placement="bottomRight">
                            <div className="basket-container"><img src={basketIcon} alt="referral-basket" className="basket-icon" /><span className="basket-title">Referral Basket</span></div>
                        </Col>
                    </Row>
                </Row>
                <LocationModal visibleModal={isModalVisible} setVisible={setIsModalVisible} />
            </Layout.Header>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
};

export default Header;
