import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Main from '../../components/layout/Main';

import './dashboard.scss';
import Tab from '../../components/Tab';
import Clinicians from '../../components/Clinicians';
import { IconBookings, IconCentres, IconFavourites, IconHome, IconProfile } from '../../components/Elements/CustomSVGIcon';

const Dashboard = () => {
    const [tab, setTab] = useState(1);
    const tabMenu = [
        { key: 1, label: 'Clinicians' },
        // { key: 2, label: 'Services' },
    ];

    const onChangeTab = ((index) => {
        setTab(index);
    });

    return (
        <Main className="login" floatingHeader>
            <div className="dashboard">
                <div className="content-container">
                    <div className="nav-container">
                        <nav>
                            <ul className="nav-list">
                                <li>
                                    <IconHome />
                                    <Link to="/dashboard">Home</Link>
                                </li>
                                <li>
                                    <IconFavourites />
                                    <Link to="/">Favourites</Link>
                                </li>
                                <li>
                                    <IconBookings />
                                    <Link to="/">Bookings</Link>
                                </li>
                                <li>
                                    <IconCentres />
                                    <Link to="/">Centres</Link>
                                </li>
                                <li>
                                    <IconProfile />
                                    <Link to="/">Profile</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="main-content">
                        <Tab defaultActiveKey="1" activeKey={tab} menu={tabMenu} onChangeTab={onChangeTab} />
                        <div className="content">
                            {tab === 1 ? <Clinicians /> : <div>Service</div>}
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
