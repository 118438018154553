/* eslint-disable no-fallthrough */
import { Record } from 'immutable';

const {
    TOGGLE_FAVOURITE,
} = require('./favouriteActions').constants;

const InitialState = Record({
    favouriteClinicians: [],
});

export const initialState = new InitialState();
// eslint-disable-next-line default-param-last
export default function favouriteReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);
    switch (type) {

    case TOGGLE_FAVOURITE: {
        const isCurrentlyFavourite = state.favouriteClinicians.includes(payload);

        if (isCurrentlyFavourite) {
            // If the clinician is already a favorite, remove it from the favorites list
            return state
                .set('favouriteClinicians', state.favouriteClinicians.filter(
                    (id) => id !== payload,
                ));
        }
        return state
            .set('favouriteClinicians', [payload, ...state.favouriteClinicians]);

    }

    default:
        return state;

    }
}
