import React, { useState, useEffect } from 'react';
import { Button, Input, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import InputMask from 'react-input-mask';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './security.scss';
import { cleanUpBookingData, cleanUpBookingResponse, getPatientBookingsDetailsRequest } from '../../core/bookings/bookingsActions';
import { cleanUpAvailabilityData } from '../../core/availability/availabilityActions';

const Security = () => {
    const [dob, setDob] = useState('');
    const [surname, setSurname] = useState('');
    const [isPressNext, setIsPressNext] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { messageId, methodToBook } = location.state || {};
    const { isFetching, patientBookingsDetails } = useSelector((state) => state.bookings);

    useEffect(() => {
        if (!messageId || !methodToBook) {
            navigate(-1);
        }
    }, [messageId, methodToBook, navigate]);

    const isFormValid = dob.trim() !== '' && surname.trim() !== '' && /^(\d{2})\/(\d{2})\/(\d{4})$/.test(dob);

    const handleNextClick = () => {
        if (isFormValid) {
            setIsPressNext(true);
            dispatch(getPatientBookingsDetailsRequest({ messageId, methodToBook, surname, dob }));
        }
    };

    useEffect(() => {
        dispatch(cleanUpBookingData());
        dispatch(cleanUpAvailabilityData());
        dispatch(cleanUpBookingResponse());
        localStorage.clear();
        // eslint-disable-next-line no-undef
        sessionStorage.clear();
    }, []);

    useEffect(() => {
        if (isPressNext && !isEmpty(patientBookingsDetails)) {
            navigate('/appointment');
            setIsPressNext(false);
        }
    }, [isPressNext, patientBookingsDetails, navigate]);

    if (!messageId || !methodToBook) return null;

    return (
        <div className="security-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="security-overlay" />
            <div className="security-content">
                <div className="security-form">
                    <h1 className="security-title">Security</h1>
                    <p className="security-description">Please confirm your Date of Birth and Surname to access your bookings.</p>
                    <label htmlFor="dob">Date of Birth</label>
                    <InputMask
                        id="dob"
                        mask="99/99/9999"
                        placeholder="dd/mm/yyyy"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}>
                        {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                    <label htmlFor="surname">Surname</label>
                    <Input
                        id="surname"
                        placeholder="Surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)} />
                </div>
                <Button
                    type="primary"
                    className="next-button no-hover"
                    icon={isFetching ? <Spin size="small" /> : null}
                    onClick={handleNextClick}
                    disabled={!isFormValid || isFetching}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default Security;
