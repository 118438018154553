/* eslint-disable default-param-last */
/* eslint-disable no-fallthrough */
import { Record } from 'immutable';
import moment from 'moment';
import { find, findKey, forEach, isEmpty, isNaN, isUndefined } from 'underscore';

import { REDUX_DATE_FORMAT } from '../utils/helpers';
import { serviceOpeningHours, LIMITED_AVAILABILITY_THRESHOLD } from '../utils/values';

const {
    POST_AVAILABILITY_REQUEST,
    POST_AVAILABILITY_SUCCESS,
    POST_AVAILABILITY_FAILURE,

    SET_SELECTED_SLOT,

    CLEAN_UP_AVAILABILITY_DATA,
} = require('./availabilityActions').constants;

const InitialState = Record({
    selectedSlots: {},
    availabilitySlots: {},
    firstAvailableSlot: {},
    availabilityDetail: {},
    resourceValues: {},
    isFetching: false,
    error: null,
});

export const initialState = new InitialState();

/**
 * Determines the availability status based on available slot count.
 *
 * @param {number} availableSlotCount - The number of available slots.
 *
 * @returns {string} The availability status.
 */
const getAvailability = (availableSlotCount) => {
    if (availableSlotCount === 0) {
        return 'unavailable';
    }

    if (availableSlotCount < LIMITED_AVAILABILITY_THRESHOLD) {
        return 'limited';
    }

    return 'available';
};

/**
 * Creates a structure with time slots based on open hours, start time and slot duration.
 *
 * @param {Object} rangeAvailabilitySlots - An object with date as keys and an object as value.
 * @param {moment} startTime - The start time for which to calculate the time slots.
 * @param {moment} endTime - The end time for which to calculate the time slots.
 * @param {number} interval - The interval in minutes.
 *
 * @returns {Object} An object with date as keys and an object as value, which includes availability and slots.
 */
const getTimeSlotsFromRange = (rangeAvailabilitySlots, startTime, endTime, interval = 15) => {
    if (isNaN(interval) || typeof interval !== 'number') {
        throw new Error('interval must be a number');
    }

    let availableSlotCount = 0;
    const timeRanges = [];
    while (startTime.isBefore(endTime)) {
        const slotDate = startTime.clone().format(REDUX_DATE_FORMAT);
        const slotTime = startTime.clone().format('HH:mm');
        const isAvailable = !isUndefined(rangeAvailabilitySlots?.[slotDate]?.[slotTime]);
        const { resources } = rangeAvailabilitySlots?.[slotDate]?.[slotTime] ?? {};

        if (isAvailable) {
            availableSlotCount += 1;
        }

        timeRanges.push({
            startTime: startTime.clone().format('HH:mm'),
            isCheckAvailability: false,
            isAvailable,
            resources,
        });
        startTime.add(interval, 'minutes');
    }

    return {
        availability: getAvailability(availableSlotCount),
        slots: timeRanges,
    };
};

const repeatOpeningHours = (openingHours, times) => {
    const repeated = [];
    for (let i = 0; i < times; i++) {
        repeated.push(...openingHours.map((hour) => `${hour} (Week ${i + 1})`));
    }
    return repeated;
};

/**
 * Creates a structure with business days and corresponding available timeslots based on open hours, start date and slot duration.
 *
 * @param {Array} availableSlots - An array of available time slots.
 * @param {Array} openingHours - An array of opening hours for each business day.
 * @param {string} startDate - The start date for which to calculate the time slots.
 * @param {number} slotDuration - The duration of each slot in minutes.
 *
 * @returns {Object} An object with date as keys and an object as value, which includes slotInterval and slots.
 */
const getTimeSlots = (availableSlots, openingHours, startDate, slotDuration, resourceType) => {
    const repeatedOpeningHours = repeatOpeningHours(openingHours, 4);
    return repeatedOpeningHours.reduce((acc, curr, index) => {
        const [, startTime, endTime] = curr.split(' - ');
        const weekday = moment(startDate, REDUX_DATE_FORMAT).add(index, 'days').format(REDUX_DATE_FORMAT);
        const availableSlotsForDay = availableSlots?.[weekday];
        const actualStartTime = availableSlotsForDay
            ? Object.keys(availableSlotsForDay)?.[0] // First available slot time for the current day
            : startTime;
        const startMoment = moment(`${weekday} ${actualStartTime}`, 'YYYY-MM-DD HH:mm');
        const endMoment = moment(`${weekday} ${endTime}`, 'YYYY-MM-DD HH:mm');

        if (!endTime) {
            return { ...acc };
        }

        const slots = getTimeSlotsFromRange(availableSlots, startMoment, endMoment, slotDuration, resourceType);

        return {
            ...acc,
            [weekday]: {
                slotDuration,
                ...slots,
            },
        };
    }, {});
};

/**
 * Gets the first available slot from the available slots.
 *
 * @param {Object} availabilitySlots - An object with date as keys and an object as value.
 *
 * @returns {Object} An object with date and slot as keys.
 */
const getFirstAvailableSlot = (availabilitySlots) => {
    let firstAvailableSlot = null;
    let firstAvailableResource = null;
    let firstAvailableIsAvailable = false;
    const firstAvailableDate = findKey(availabilitySlots, (slots) => !isEmpty(find(slots.slots, (slot) => {
        if (slot.isAvailable) {
            firstAvailableSlot = slot.startTime;
            firstAvailableResource = slot.resources;
            firstAvailableIsAvailable = true;
        }

        return slot.isAvailable;
    })));

    if (!firstAvailableDate) {
        return null;
    }

    return {
        slot: firstAvailableSlot,
        date: firstAvailableDate,
        resources: firstAvailableResource,
        isAvailable: firstAvailableIsAvailable,
    };
};

export default function availabilityReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    let tempResourceValues = {};

    const getAvailableSlots = (resources) => {
        const availableSlots = {};
        forEach(resources, resource => {
            const { Sessions: sessions, ...resourceDetails } = resource;
            const { CCResourceID: resourceId, ResourceName: resourceName, ResourceType: resourceType } = resourceDetails;

            tempResourceValues = {
                ...tempResourceValues,
                ...state.resourceValues,
                [resourceId]: {
                    resourceId,
                    resourceName,
                    resourceType,
                },
            };

            const slots = sessions?.flatMap(session => session.Slots);

            forEach(slots, slot => {
                const { SlotDate, SlotTime } = slot;
                const startTime = moment(`${SlotDate} ${SlotTime}`, 'YYYY-MM-DD HH:mm:ss', true).format('HH:mm');

                availableSlots[SlotDate] = {
                    ...availableSlots?.[SlotDate] || {},
                    [startTime]: {
                        resources: [
                            ...availableSlots?.[SlotDate]?.[startTime]?.resources ?? [],
                            resourceId,
                        ],
                    },
                };
            });
        });

        return availableSlots;
    };

    switch (type) {

    case SET_SELECTED_SLOT: {
        const { serviceId, ...selectedSlot } = payload;
        let newSelectedSlots = {};

        if (serviceId) {
            newSelectedSlots = {
                ...state.selectedSlots,
                [serviceId]: selectedSlot,
            };
        }
        return state.set('selectedSlots', newSelectedSlots);
    }

    case POST_AVAILABILITY_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case POST_AVAILABILITY_SUCCESS: {
        const { serviceId, startDate, availability } = payload;
        const {
            Resources: resources = [],
            ...availabilityDetails
        } = availability || {};
        const getFirstSlotInterval = (resourcesItem) => {
            if (!Array.isArray(resourcesItem) || resourcesItem?.length === 0) return null;
            const firstResource = resourcesItem[0];
            if (firstResource.Sessions?.length === 0) return null;
            return firstResource.Sessions[0].SlotInterval;
        };

        const slotDuration = getFirstSlotInterval(resources) || 30;

        const newServiceTimeSlots = getTimeSlots(getAvailableSlots(resources), serviceOpeningHours, startDate, slotDuration);

        const currentServiceTimeSlots = state.availabilitySlots[serviceId] || {};

        const mergedAvailabilitySlots = Object.keys(currentServiceTimeSlots)
            .reduce((acc, dateKey) => {
                if (newServiceTimeSlots[dateKey]) {
                    acc[dateKey] = {
                        ...currentServiceTimeSlots[dateKey],
                        ...newServiceTimeSlots[dateKey], // Override with the new availability for the same date
                    };
                }
                return acc;
            }, {});

        Object.keys(newServiceTimeSlots).forEach((dateKey) => {
            if (!mergedAvailabilitySlots[dateKey]) {
                mergedAvailabilitySlots[dateKey] = newServiceTimeSlots[dateKey];
            }
        });

        const sortedAvailabilitySlots = Object.keys(mergedAvailabilitySlots).sort().reduce((acc, key) => {
            acc[key] = mergedAvailabilitySlots[key];
            return acc;
        }, {});

        let firstAvailableSlot = state.firstAvailableSlot[serviceId];
        if (isEmpty(firstAvailableSlot)) {
            firstAvailableSlot = getFirstAvailableSlot(sortedAvailabilitySlots);
        }

        return state
            .set('availabilitySlots', {
                ...state.availabilitySlots,
                [serviceId]: sortedAvailabilitySlots,
            })
            .set('firstAvailableSlot', {
                ...state.firstAvailableSlot,
                [serviceId]: firstAvailableSlot,
            })
            .set('availabilityDetail', availabilityDetails)
            .set('resourceValues', tempResourceValues)
            .set('isFetching', false);
    }

    case POST_AVAILABILITY_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);

    case CLEAN_UP_AVAILABILITY_DATA:
        return initialState;

    default:
        return state;

    }
}
