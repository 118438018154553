import React from 'react';
import logoIcon from '../../assets/images/welbeck-logo.png';
import RadioGroup from '../../components/RadioGroup';
import AppointmentDetails from '../../components/AppointmentDetails';
import PaymentMethod from '../../components/PaymentMethod';
import './payment.scss';
import ProgressBar from '../../components/ProgressBar';

const appointments = [
    { id: 1, title: 'MRI Brain, Contrast, Bilateral', time: '15:30', date: '23rd March 2023', price: 300 },
    { id: 2, title: 'MRI Elbow, Contrast, Right', time: '16:00', date: '23rd March 2023', price: 240 },
];

const Payment = () => {
    const options = [
        { value: 'selfPay', label: 'Self Pay' },
        { value: 'insurance', label: 'Insurance' },
        { value: 'sponsor', label: 'Sponsor' },
        { value: 'embassy', label: 'Embassy' },
    ];

    const handleRadioChange = (value) => {
        console.log('Selected value:', value);
    };

    return (
        <div className="payment-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={3} />
            </div>
            <p className="approval-info">
                Your appointment will still have to be approved by the clinician once the form is completed.
            </p>
            <div className="funding-options">
                <p className="funding-title">How will this procedure be funded?</p>
                <RadioGroup options={options} defaultValue="selfPay" onChange={handleRadioChange} />
            </div>
            <AppointmentDetails appointments={appointments} />
            <PaymentMethod />
        </div>
    );
};

export default Payment;
