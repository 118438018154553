import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './confirm.scss';

const Confirm = () => (
    <div className="confirm-page">
        <div className="header">
            <img src={logoIcon} alt="Logo" className="logo" />
        </div>
        <div className="result-container">
            <CheckCircleOutlined className="result-icon success" />
            <h1 className="result-title">Thank you for booking!</h1>
            <p className="result-message">
                You will receive email confirmation shortly.
                The centre will review your request and will approve the appointment, or contact you with an alternative date.
            </p>
        </div>
    </div>
);

export default Confirm;
