import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ServiceCard from '../ServiceCard';
import Card from '../Card';
import { cardMockData, serviceDetailsMock } from '../../core/utils/values';

// import * as RootNavigation from '../../core/utils/navigation';
import { toggleFavourite } from '../../core/favourite/favouriteActions';
import './clinicians.scss';

const renderCard = ({ item }) => {
    const { color, region, profileId, clinicianId, handleFavoriteToggle, fullName, specialityName, subFields, imageUrl, availability, onPressCard, filteredClinicians } = item;

    return (
        <Card
            key={clinicianId}
            profileId={profileId}
            clinicianId={clinicianId}
            title={fullName}
            specialityName={specialityName}
            subFields={subFields}
            portalCentreId={region}
            imageSrc={imageUrl}
            onPress={onPressCard}
            onFavourite={handleFavoriteToggle}
            favourite={filteredClinicians}
            availability={availability}
            color={color} />
    );
};

const Clinicians = () => {
    const { favouriteClinicians } = useSelector((state) => state?.favourite);
    // const { clinicians, isFetching } = useSelector((state) => state?.clinician);
    // const { userLocation } = useSelector(state => state.user);
    // const { specialities } = useSelector(state => state.speciality);
    const clinicians = cardMockData;
    const isFetching = false;
    const specialities = serviceDetailsMock;
    const dispatch = useDispatch();
    const renderService = ({ item }) => {
        const { specialityName, color, specialityId, imageUrl } = item;

        const onPress = () => {
            console.log({ specialityName });
            // RootNavigation.navigate('Speciality', {
            //     id: specialityId,
            //     color,
            // });
        };

        return (
            <ServiceCard
                key={specialityId}
                title={specialityName}
                imageSrc={imageUrl}
                onPress={onPress}
                borderColor={color} />
        );
    };

    const orderService = specialities?.sort((a, b) => a.specialityName.localeCompare(b.specialityName));

    const renderClinicianData = useMemo(() => {
        const newClinician = clinicians.reduce((acc, clinician) => {
            const { clinicianId, fullName, imageUrl, portalCentreId, profileId, specialityId, specialityName, subFields, color, availability } = clinician;

            const _newClinician = {
                clinicianId,
                fullName,
                imageUrl,
                portalCentreId,
                profileId,
                specialityId,
                specialityName,
                subFields,
                color,
                availability,
            };

            if (acc[specialityId]) {
                acc[specialityId].push(_newClinician);
            } else {
                acc[specialityId] = [_newClinician];
            }

            return acc;
        }, {});
        const newClinicianArray = Object.values(newClinician);
        return newClinicianArray;
    }, [clinicians]);

    const renderListClinician = (clinician, index) => {
        const specialityTitle = clinician[0]?.specialityName || '';
        const renderCardData = () => {
            const cardDataArray = clinician.map((item) => {
                const { profileId, clinicianId, fullName, subFields, portalCentreId, imageUrl, availability, color, specialityName } = item;
                const region = portalCentreId;
                // !== userLocation?.id ? userLocation.title : null;
                const onPressCard = () => {
                    console.log('card');
                    // RootNavigation.navigate('ClinicianProfile', {
                    //     id: profileId,
                    // });
                };

                const handleFavoriteToggle = () => {
                    dispatch(toggleFavourite(profileId));
                };

                const filteredClinicians = favouriteClinicians.includes(profileId);

                return {
                    profileId,
                    clinicianId,
                    fullName,
                    specialityName,
                    imageUrl,
                    subFields,
                    availability,
                    color,
                    region,
                    onPressCard,
                    handleFavoriteToggle,
                    filteredClinicians,
                };
            });

            return cardDataArray;

        };

        return (
            <div key={`specialityTitle - ${index}`} className="speciality-container">
                <span className="topic-text">{specialityTitle}</span>
                <div className="flatlist-container">
                    {renderCardData().map((item) => (
                        <div key={item.clinicianId} className="flatlist-item">
                            {renderCard({ item })}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="clinician-container">
            <div className="service-flatlist-container">
                {orderService.map((item) => (
                    renderService({ item })
                ))}
            </div>
            {isFetching && (
                <div className="loading-spinner">
                    <div className="spinner" />
                </div>
            )}
            {false
                && (
                    <div className="lastbook-container">
                        <span className="topic-text">Last Booked Clinicians</span>
                        <div className="lastbook-item">
                            {clinicians.map((item) => (
                                <Card
                                    key={item.clinicianId}
                                    profileId={item.profileId}
                                    clinicianId={item.clinicianId}
                                    title={item.fullName}
                                    specialityName={item.specialityName}
                                    subFields={item.subFields}
                                    portalCentreId={item.portalCentreId}
                                    imageSrc={item.imageUrl}
                                    onPress={item.onPressCard}
                                    onFavourite={item.handleFavoriteToggle}
                                    favourite={item.filteredClinicians}
                                    availability={item.availability}
                                    color={item.color} />
                            ))}
                        </div>
                    </div>
                )}
            {renderClinicianData.map((clinic, index) => (
                renderListClinician(clinic, index)
            ))}
        </div>
    );
};

export default React.memo(Clinicians);
