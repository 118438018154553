import React from 'react';
import PropTypes from 'prop-types';
import './progressBar.scss';

const ProgressBar = ({ progress }) => (
    <div className="progress-bar-container">
        <div className="progress-bar">
            <div className={`progress-point start ${progress >= 1 ? 'completed' : ''}`} />
            <div className={`progress-line ${progress >= 2 ? 'completed' : ''}`} />
            <div className={`progress-point middle ${progress >= 2 ? 'completed' : ''}`} />
            <div className={`progress-line ${progress >= 3 ? 'completed' : ''}`} />
            <div className={`progress-point end ${progress >= 3 ? 'completed' : ''}`} />
        </div>
    </div>
);

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
};

export default ProgressBar;
