import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './accordion.scss';
import { IconChevronDown, IconChevronUp } from '../Elements/CustomSVGIcon';

const Accordion = ({ data, renderItem, renderContent, service, extraData, bloodTest, basket }) => {
    const [openIndices, setOpenIndices] = useState([]);

    useEffect(() => {
        setOpenIndices([]);
    }, []);

    const toggleSelectedItem = (id) => {
        setOpenIndices(openIndices.includes(id)
            ? openIndices.filter(index => index !== id)
            : [...openIndices, id]);
    };

    const getOpenStatus = (id) => openIndices.includes(id);

    const renderIcon = (id, anyContentSelected) => {
        const purple = '#9D377D';
        const white = '#ffffff';
        const welbeckBlue = '#202B5B';
        if (getOpenStatus(id)) {
            return <IconChevronUp color={purple} />;
        }
        return <IconChevronDown color={service && anyContentSelected ? white : welbeckBlue} />;
    };

    const renderTogglableRow = ({ item, index }) => {
        const { id, title, content } = item;
        const idList = Array.isArray(content) && bloodTest
            ? content.flatMap(contentItem => contentItem?.id ?? [])
            : [];
        const isLastItem = index === data.length - 1;
        const hasPermissionBasket = basket ? item.hasPermission : true;
        let isServiceRequestOptionIdMatched = false;
        if (bloodTest) {
            isServiceRequestOptionIdMatched = Object.keys(extraData).some(key => idList.includes(key));
        } else {
            isServiceRequestOptionIdMatched = extraData?.some(dataItem => content.some(contentItem => contentItem.serviceRequestOptionId === dataItem.serviceRequestOptionId));
        }
        return (
            <div key={id} className={`accordion ${service ? 'accordion-no-shadow' : ''} ${service && isLastItem ? 'no-border-bottom' : ''} ${service && isServiceRequestOptionIdMatched && !getOpenStatus(id) ? 'background-purple' : ''}`}>
                <div
                    onClick={() => hasPermissionBasket && toggleSelectedItem(id)}
                    className="accordion-header">
                    <div className="accordion-title">
                        {title || renderItem ? renderItem({ item, expanded: getOpenStatus(id), parentId: idList, index, content }) : null}
                    </div>
                    {hasPermissionBasket && (
                        <div className="accordion-icon">
                            {renderIcon(id, isServiceRequestOptionIdMatched)}
                        </div>
                    )}
                </div>
                <div className={`accordion-content-wrapper ${getOpenStatus(id) ? 'expanded' : ''}`}>
                    <div className={`accordion-content ${service ? 'accordion-no-shadow-content' : ''}`}>
                        {renderContent({ item, index })}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`accordion-container ${service ? 'accordion-service' : ''}`}>
            {data.map((item, index) => renderTogglableRow({ item, index }))}
        </div>
    );
};

Accordion.propTypes = {
    data: PropTypes.array,
    renderItem: PropTypes.func,
    renderContent: PropTypes.func,
    service: PropTypes.bool,
    extraData: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    bloodTest: PropTypes.bool,
    profile: PropTypes.bool,
    basket: PropTypes.bool,
};

Accordion.defaultProps = {
    data: [],
    renderItem: null,
    renderContent: null,
    service: false,
    extraData: [],
    bloodTest: false,
    profile: false,
    basket: false,
};

export default Accordion;
