import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import './tab.scss';

const renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar
        {...props}
        className="tabsStyl"
        style={{
            color: '#202B5B',
            fontFamily: 'Avenir Next',
        }} />
);

const Tab = ({ menu, onChangeTab }) => (
    <Tabs
        defaultActiveKey="1"
        items={menu}
        renderTabBar={renderTabBar}
        onChange={onChangeTab} />
);

Tab.propTypes = {
    onChangeTab: PropTypes.func,
    menu: PropTypes.array,
};

Tab.defaultProps = {
    onChangeTab: null,
    menu: [],
};

export default React.memo(Tab);
