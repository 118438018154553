/* eslint-disable */
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import lockr from 'lockr';
import { notification } from 'antd';
import { isUndefined } from 'underscore';

import NotFound from '../../pages/NotFound';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
    UNIQUE_MESSAGE_ID,
} = require('../constants').default;

// Function to check if the user is authorised (existing logic)
const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);
    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const parsedAuthDate = new Date(authDate);
            const now = new Date();
            const differenceMilliseconds = now - parsedAuthDate;
            const differenceMinutes = Math.floor(differenceMilliseconds / 1000 / 60);
            if (differenceMinutes >= LOCALSTORAGE_EXPIRES) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};

// New function to check if access is confirmed by both isConfirmed in Redux and UNIQUE_MESSAGE_ID in lockr
const isAccessConfirmed = () => {
    const isConfirmed = useSelector((state) => state.bookings.isConfirmed);
    const uniqueMessageId = lockr.get(UNIQUE_MESSAGE_ID);

    return isConfirmed && uniqueMessageId !== undefined && uniqueMessageId !== null;
};

const LogoutRoute = ({ exact, path, persistor }) => {
    lockr.rm(AUTH_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES);

    localStorage.clear();
    lockr.flush();
    persistor.flush();
    persistor.purge();
    window.location.href = '/';
};

// Wrapper component to handle navigation
const RedirectToPrevious = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(-1);
    }, [navigate]);

    return null;
};

const CoreRouter = ({ routes }) => {
    return (
        <Routes>
            {routes.map((route, index) => (
                route.type === 'auth' ?
                    <Route key={index} exact path={route.path} element={
                        onlyAuthorisedAllowed() ? <route.component /> : <Navigate to="/" />
                    } role={route.role} allowedOnlyRoles={route.allowedOnlyRoles} />
                    : <Route key={index} exact path={route.path} element={<route.component />} />
            ))}
            <Route element={<NotFound />} />
        </Routes>
    );
};

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    LogoutRoute,
};
