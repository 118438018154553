import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Radio from '../Radio';
import './radioGroup.scss';

const RadioGroup = ({ options, onChange, defaultValue }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const handleChange = (value) => {
        setSelectedValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className="radio-group">
            {options.map((option) => (
                <Radio
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={selectedValue === option.value}
                    onChange={handleChange} />
            ))}
        </div>
    );
};

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
};

RadioGroup.defaultProps = {
    options: [],
    onChange: null,
    defaultValue: '',
};

export default RadioGroup;
