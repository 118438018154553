import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import regionSaga from './region/regionSagas';
import bookingsSaga from './bookings/bookingsSagas';
import availabilitySagas from './availability/availabilitySagas';
import centreSaga from './centre/centreSagas';
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        regionSaga(),
        bookingsSaga(),
        availabilitySagas(),
        centreSaga(),
    ]);
}
