import React from 'react';
import { Button } from 'antd';
import './loginForm.scss';
import { Link } from 'react-router-dom';
import { resetPasswordUrl, registerUrl } from '../../../core/utils/values';
import Separator from '../../Separator';

const LoginForm = () => {

    const handleGuestPress = () => {
        console.log('Guest');
    };

    return (
        <div className="login-form-box">
            <div className="form">
                <h1>Welcome</h1>
                <h4>Select an option below to continue and access the OneWelbeck app!</h4>

                <Button type="primary" size="large" htmlType="submit" block className="mt-3 primary-btn-style" href={registerUrl}>
                    Login
                </Button>

                <Link to="/dashboard">
                    <Button type="primary" size="large" htmlType="submit" block className="mt-3 secondary-btn-style" onClick={() => handleGuestPress()}>
                        Continue as Guest
                    </Button>
                </Link>
                <div className="forgot-password-wrap">
                    <span style={{ fontSize: 16, marginRight: 5 }}>
                        Have you forgot your password?
                    </span>
                    <Button type="link" className="button-link" href={resetPasswordUrl}>
                        Reset Password
                    </Button>
                </div>
                <Separator fullWidth />
                <div className="forgot-password-wrap">
                    <span style={{ fontSize: 16, marginRight: 5 }}>
                        Want to create an account?
                    </span>
                    <Button type="link" className="button-link" href={registerUrl}>
                        Register Now
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
