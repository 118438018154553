import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import './successModal.scss';

const SuccessModal = ({ visible, onClose }) => (
    <Modal open={visible} footer={null} centered onCancel={onClose} closeIcon={false} className="request-modal">
        <div className="success-modal-content">
            <CheckCircleOutlined className="result-icon success" />
            <p>
                Your request for a call back has been submitted. Please wait for our
                team to get back to you to complete the booking.
            </p>
        </div>
    </Modal>
);

SuccessModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
