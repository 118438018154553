import React from 'react';
import PropTypes from 'prop-types';
import { RadioCheckedIcon, RadioUncheckedIcon } from '../Elements/CustomSVGIcon';
import './radio.scss';

const Radio = ({ onChange, checked, disabled, value, label }) => {
    const handleClick = () => {
        if (!disabled && onChange) {
            onChange(value);
        }
    };

    return (
        <div className={`radio-container ${checked ? 'active' : 'inactive'}`} onClick={handleClick}>
            {checked ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
            <span className="radio-label">{label}</span>
        </div>
    );
};

Radio.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
};

Radio.defaultProps = {
    onChange: null,
    checked: false,
    disabled: false,
    value: '',
    label: '',
};

export default Radio;
