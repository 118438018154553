import React from 'react';
import PropTypes from 'prop-types';
import './customRadio.scss';

const CustomRadio = ({ options, name, selectedOption, onChange, disabled }) => (
    <div className="custom-radio-group">
        {options.map((option) => (
            <label key={option.value} className="custom-radio-label">
                <input
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                    className="custom-radio-input" />
                <span className={`custom-radio-button ${option.colorClass}`} />
                <span className="custom-radio-text">{option.label}</span>
            </label>
        ))}
    </div>
);

CustomRadio.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        colorClass: PropTypes.string,
    })).isRequired,
    name: PropTypes.string.isRequired,
    selectedOption: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

CustomRadio.defaultProps = {
    disabled: false,
};

export default CustomRadio;
