import React from 'react';
import PropTypes from 'prop-types';
import { IconFavourites } from '../Elements/CustomSVGIcon';
import './favouriteButton.scss';

const FavouriteButton = ({ profileId, onFavourite, favourite, clinicianProfile }) => {
    const handleFavPress = () => {
        onFavourite(profileId);
    };

    return (
        <div className="favourite-container" onClick={() => handleFavPress()}>
            <IconFavourites w={clinicianProfile ? 25 : 30} h={clinicianProfile ? 25 : 30} f={favourite ? '#202B5B' : null} s="#202B5B" />
        </div>
    );
};

FavouriteButton.propTypes = {
    favourite: PropTypes.bool,
    profileId: PropTypes.number,
    onFavourite: PropTypes.func,
    clinicianProfile: PropTypes.bool,
};

FavouriteButton.defaultProps = {
    favourite: false,
    profileId: null,
    onFavourite: () => {},
    clinicianProfile: false,
};

export default React.memo(FavouriteButton);
