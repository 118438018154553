import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import './failureModal.scss';

const FailureModal = ({ visible, onClose }) => (
    <Modal open={visible} footer={null} centered onCancel={onClose} closeIcon={false} className="failure-modal">
        <div className="failure-modal-content">
            <p>
                Unfortunately, you cannot book this service without booking a prior
                consultation.
            </p>
            <p>Please search and book a consultation with a relevant clinician.</p>
            <Button type="primary" block onClick={onClose} className="search-button">
                Search Clinicians
            </Button>
        </div>
    </Modal>
);

FailureModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default FailureModal;
