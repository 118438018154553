import { put, call, takeEvery } from 'redux-saga/effects';

import { message } from 'antd';
import { fetchApi } from '../utils/api';

import {
    getRegionsSuccess,
    getRegionsFailure,
} from './regionActions';

const {
    GET_REGIONS_REQUEST,
} = require('./regionActions').constants;

function* getRegions() {

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/getRegionList',
        });
        yield put(getRegionsSuccess(response?.data));
    } catch (e) {
        message.error('Error', e.response?.data?.message || 'Something went wrong');
        yield put(getRegionsFailure(e.response ? e.response.data.message : e));
    }
}

export default function* filterSagas() {
    yield* [
        takeEvery(GET_REGIONS_REQUEST, getRegions),
    ];
}
