import React from 'react';
import PropTypes from 'prop-types';
import './availabilityFlag.scss';

const AvailabilityFlag = ({ availability }) => {
    console.log('availability');

    return (
        <div className="availability-container">
            <span className="availability-text">{availability}</span>
        </div>
    );
};

AvailabilityFlag.propTypes = {
    availability: PropTypes.string,
    compact: PropTypes.bool,
    clinicianProfile: PropTypes.bool,
};

AvailabilityFlag.defaultProps = {
    availability: null,
    compact: false,
    clinicianProfile: false,
};

export default React.memo(AvailabilityFlag);
