import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './beginBooking.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import bgSrcDeskt from '../../assets/images/welcome-background.png';
import { cleanUpBookingData, cleanUpBookingResponse } from '../../core/bookings/bookingsActions';
import { cleanUpAvailabilityData } from '../../core/availability/availabilityActions';

const BeginBooking = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { patientBookingsDetails } = useSelector(state => state?.bookings);
    const [searchParams] = useSearchParams();

    const messageId = searchParams.get('messageId');
    const methodToBook = searchParams.get('methodToBook');

    const navigateToSecurity = () => {
        navigate('/security', { state: { messageId, methodToBook } });
    };

    useEffect(() => {
        if (!isEmpty(patientBookingsDetails)) {
            dispatch(cleanUpBookingData());
            dispatch(cleanUpAvailabilityData());
            dispatch(cleanUpBookingResponse());
            localStorage.clear();
            // eslint-disable-next-line no-undef
            sessionStorage.clear();
        }
    }, [patientBookingsDetails]);

    const isBookingEnabled = messageId && methodToBook;

    return (
        <div className="bookings-wrap">
            <div className="bookings-info">
                <img src={bgSrcDeskt} alt="" className="bg-image" />
            </div>
            <div className="booking-page" title="Book your appointment">
                <div className="booking-content">
                    <h1 className="booking-title">Book your appointment</h1>
                    <p className="booking-description">Your Welbeck clinician or GP has begun creating an appointment for you. Please fill out this form to complete your booking.</p>
                    <Button type="primary" disabled={!isBookingEnabled} className="begin-button" onClick={navigateToSecurity}>Begin</Button>
                </div>
            </div>
        </div>
    );
};

export default BeginBooking;
