/* eslint-disable no-fallthrough */
import { Record } from 'immutable';
import { first } from 'underscore';

const {
    GET_CENTRE_PROFILE_REQUEST,
    GET_CENTRE_PROFILE_SUCCESS,
    GET_CENTRE_PROFILE_FAILURE,
} = require('./centreAction').constants;

const InitialState = Record({
    centreProfile: {},
    isFetching: false,
});

export const initialState = new InitialState();

// eslint-disable-next-line default-param-last
export default function centreReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_CENTRE_PROFILE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_CENTRE_PROFILE_SUCCESS: {
        const { centre } = payload;
        const {
            Address,
            CentreDirector,
            CentreImageURL,
            DefaultAlertEmail,
            DefaultNumber,
            MedicalDirector,
            PortalCentreID,
            PortalCentreName,
            PortalCentreSubtitle,
            PortalRegionId,
            PostCode,
            WebPageURL,
            WebsiteURL,
            About,
        } = first(centre);

        const centreProfile = {
            address: Address,
            centreDirector: CentreDirector,
            centreImageURL: CentreImageURL,
            defaultAlertEmail: DefaultAlertEmail,
            defaultNumber: DefaultNumber,
            medicalDirector: MedicalDirector,
            portalCentreId: PortalCentreID,
            portalCentreName: PortalCentreName,
            portalCentreSubtitle: PortalCentreSubtitle?.replace(/\r\n/g, ''),
            portalRegionId: PortalRegionId,
            postCode: PostCode,
            webPageURL: WebPageURL,
            websiteURL: WebsiteURL,
            about: About,
        };

        return state
            .set('centreProfile', centreProfile)
            .set('isFetching', false);
    }

    case GET_CENTRE_PROFILE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
