import React from 'react';
import PropTypes from 'prop-types';
// import styles from './styles'; // You'll need to adjust this import based on your project structure
import './tag.scss'; // You'll need to adjust this import for your CSS file

const Tag = ({ specialityName, subFields, color, clinicianProfile }) => (
    <div className="tag-container">
        <div className={`main-field-container ${clinicianProfile ? 'profile-padding' : ''}`}>
            <div className={`main-field ${clinicianProfile ? 'profile-margin' : ''}`} style={{ backgroundColor: color }}>
                <span className={`main-field-text ${clinicianProfile ? 'font-size-16' : ''}`}>
                    {specialityName}
                </span>
            </div>
        </div>
        {subFields && (
            <div className="sub-field-container" style={{ flexDirection: 'row', display: 'flex', overflow: 'hidden' }}>
                {subFields.map((subField) => (
                    <div key={subField.id} className="sub-field" style={{ borderColor: color }}>
                        <span className="sub-field-text font-size-14" title={subField.fieldTitle} style={{ color }}>
                            {subField.fieldTitle}
                        </span>
                    </div>
                ))}
            </div>
        )}
    </div>
);

Tag.propTypes = {
    specialityName: PropTypes.string,
    subFields: PropTypes.array,
    color: PropTypes.string,
    clinicianProfile: PropTypes.bool,
};

Tag.defaultProps = {
    specialityName: '',
    subFields: null,
    color: '',
    clinicianProfile: false,
};

export default React.memo(Tag);
