import React from 'react';
import PropTypes from 'prop-types';
import './serviceCard.scss';

import fallbackImage from '../../assets/images/fallback.png';

const ServiceCard = ({ title, onPress, imageSrc, borderColor }) => {
    const handleBodyPress = () => {
        onPress();
    };

    return (
        <div className="service-container">
            <div key={title} onClick={handleBodyPress}>
                <div className="service-item" style={{ borderColor }}>
                    <img
                        className="service-image"
                        src={imageSrc || fallbackImage}
                        alt="" />
                    <div className="service-overlay" />
                    <span className="service-title">{title}</span>
                </div>
            </div>
        </div>
    );
};

ServiceCard.propTypes = {
    onPress: PropTypes.func,
    title: PropTypes.string,
    imageSrc: PropTypes.string,
    borderColor: PropTypes.string,
};

ServiceCard.defaultProps = {
    onPress: () => {},
    title: 'Title',
    imageSrc: null,
    borderColor: null,
};

export default React.memo(ServiceCard);
