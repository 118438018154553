/* eslint-disable no-fallthrough */
import { Record } from 'immutable';

const {
    GET_REGIONS_REQUEST,
    GET_REGIONS_SUCCESS,
    GET_REGIONS_FAILURE,
} = require('./regionActions').constants;

const InitialState = Record({
    regions: [],
    isFetching: false,
    error: null,
});

export const initialState = new InitialState();

// eslint-disable-next-line default-param-last
export default function regionReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_REGIONS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_REGIONS_SUCCESS: {
        const { regions } = payload;

        const reduxRegions = regions?.map(region => ({
            id: region.PortalRegionID,
            title: region.PortalRegionName,
        }));

        return state.set('isFetching', false)
            .set('regions', reduxRegions || []);
    }

    case GET_REGIONS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
