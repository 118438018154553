import React from 'react';
import PropTypes from 'prop-types';
import './timeSlot.scss';
import { Button } from 'antd';

const TimeSlot = ({ label, selected, disabled, onClick }) => {
    const buttonClass = `time-slot-button ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`;

    return (
        <Button onClick={onClick} disabled={disabled} className={buttonClass}>
            {label}
        </Button>
    );
};

export default TimeSlot;

TimeSlot.propTypes = {
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

TimeSlot.defaultProps = {
    selected: false,
    disabled: false,
    onClick: () => {},
};
