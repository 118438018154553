import React from 'react';

import { CoreRouter, LogoutRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ResetPassword from '../pages/ResetPassword';
import TwoFactorAuth from '../pages/TwoFactorAuth';
import BeginBooking from '../pages/BeginBooking';
import Security from '../pages/Security';
import Appointment from '../pages/Appointment';
import Availability from '../pages/Availability';
import AttachDocument from '../pages/AttachDocument';
import BookingResult from '../pages/BookingResult';
import Payment from '../pages/Payment';
import Confirm from '../pages/Confirm';

const Routes = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/dashboard', component: Dashboard, type: 'default' },
        { path: '/reset-password/:token/:email', component: ResetPassword, type: 'default' },
        { path: '/two-factor', component: TwoFactorAuth, type: 'default' },
        { path: '/logout', component: LogoutRoute, type: 'default' },
        { path: '/begin-booking', component: BeginBooking, type: 'default' },
        { path: '/security', component: Security, type: 'default' },
        { path: '/appointment', component: Appointment, type: 'restricted' },
        { path: '/availability', component: Availability, type: 'restricted' },
        { path: '/attach-document', component: AttachDocument, type: 'restricted' },
        { path: '/booking-result', component: BookingResult, type: 'restricted' },
        { path: '/payment', component: Payment, type: 'restricted' },
        { path: '/confirm', component: Confirm, type: 'restricted' },
        { path: '/auth/dashboard', component: Dashboard, type: 'auth' },
    ]} />
);

export default Routes;
