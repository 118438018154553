import React from 'react';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
// import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';
// import { getRegionsRequest } from '../../../core/region/regionActions';
import locationIcon from '../../../assets/images/location-icon.png';
import { setUserLocation } from '../../../core/user/userActions';
import './locationmodal.scss';
import { regions } from '../../../core/utils/values';

const LocationPicker = ({ visibleModal, setVisible }) => {
    // const { regions, isFetching } = useSelector((state) => state.region);

    const dispatch = useDispatch();

    // useEffect(() => {
    // if (!isFetching && isEmpty(regions)) {
    //     dispatch(getRegionsRequest());
    // }
    // }, []);

    const handleModalClose = () => {
        setVisible(false);
    };

    const setLocation = (val) => {
        dispatch(setUserLocation(val));
        setVisible(false);
    };

    return (
        <Modal
            open={visibleModal}
            onCancel={handleModalClose}
            footer={null}
            centered>
            <div className="modal-content">
                <h2 className="title">Select Welbeck Location</h2>
                <div>
                    {regions.map((location) => (
                        <div key={location.id}>
                            <Button
                                type="link"
                                className="location-button"
                                onClick={() => setLocation(location)}>
                                <img src={locationIcon} alt="location" className="location-icon" />
                                <span className="location-item">{location.title}</span>
                            </Button>
                            <div className="separate-line" />
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

LocationPicker.defaultProps = {
    visibleModal: false,
    setVisible: () => {},
};

LocationPicker.propTypes = {
    visibleModal: PropTypes.bool,
    setVisible: PropTypes.func,
};

export default LocationPicker;
