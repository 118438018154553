import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_CENTRE_PROFILE_REQUEST: null,
    GET_CENTRE_PROFILE_SUCCESS: null,
    GET_CENTRE_PROFILE_FAILURE: null,
});

export const {
    getCentreProfileRequest,
    getCentreProfileSuccess,
    getCentreProfileFailure,
} = createActions(
    constants.GET_CENTRE_PROFILE_REQUEST,
    constants.GET_CENTRE_PROFILE_SUCCESS,
    constants.GET_CENTRE_PROFILE_FAILURE,
);
