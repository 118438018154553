import React from 'react';
import PropTypes from 'prop-types';
import { IconTickFilled } from '../Elements/CustomSVGIcon';
import './appointmentDetails.scss';

const AppointmentDetails = ({ appointments }) => (
    <div className="appointment-details">
        {appointments.map((appointment) => (
            <div key={appointment.id} className="appointment">
                <h3>{appointment.title}</h3>
                <p className="appointment-time">
                    <IconTickFilled className="icon" /> {appointment.time} on {appointment.date}
                </p>
                <div className="divider" />
                <p className="appointment-price">
                    <span className="price-label">Price:</span> £{appointment.price}
                </p>
            </div>
        ))}
        <div className="total-price">
            <span className="price-label">Total Price:</span> £{appointments.reduce((acc, curr) => acc + curr.price, 0)}
        </div>
    </div>
);

AppointmentDetails.propTypes = {
    appointments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        }),
    ).isRequired,
};

export default AppointmentDetails;
