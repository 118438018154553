import axios from 'axios';
import qs from 'qs';
import lockr from 'lockr';
import { isUndefined, isEmpty, isObject } from 'underscore';

// load mock data if enabled
import './mock';

const {
    API_URL,
    AUTH_KEY,
    BACKEND,
    GOOGLE_MAPS_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

// ----- Helpers ----- //

// Get Api Url
const getApiUrl = (version = 'v1') => `${API_URL}/api/${version}`;

// Get hostname url
const getBaseUrl = () => API_URL;

// Get locational data
const locationData = name => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);

// Format params for api call
const formatParams = (payload, key) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }

    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //

function fetchApi(opts, headers) {

    let data = (opts.method.toUpperCase() === 'GET') ? null : opts.body;
    let laravelHeaders = {};
    const { version } = opts;

    if (BACKEND === 'yii') {
        data = data ? qs.stringify(data) : null;
    } else {
        laravelHeaders = {
            'X-Requested-With': 'XMLHttpRequest',
            OneWelbeckAuthorizationHeader: 'Oq/2+ZraczGRZbcwyeKhi+Se5G27/tkB',
        };
    }

    // defaults for axios
    const api = axios.create({
        baseURL: getApiUrl(version || 'v1'),
    });

    const options = {
        method: opts.method,
        url: opts.url,
        params: opts.params,
        headers: laravelHeaders,
    };

    if (opts.method.toUpperCase() !== 'GET') {
        options.data = data;
    }

    if (!isEmpty(headers)) {
        options.headers = { ...options.headers, ...headers };
    }

    return api(options);
}

function fetchApiAuth(opts) {
    return new Promise((resolve, reject) => {
        if (isUndefined(getAuthKey())) {
            reject(new Error('not-authorised'));
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            resolve(fetchApi(opts, getAuthHeaders()));
        }
    });
}

export {
    getApiUrl,
    getBaseUrl,
    locationData,
    formatParams,

    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,

    fetchApi,
    fetchApiAuth,
};
