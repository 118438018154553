import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'; // Import your CSS file

function Separator({ text, hideText, fullWidth, containerStyle }) {
    const renderSeparatorStroke = () => (
        <div className={`separator ${fullWidth ? 'full-width' : ''}`}>
            <div className="separator-stroke" />
        </div>
    );

    return (
        <div className={`separator-container ${fullWidth ? 'full-width' : ''}`} style={containerStyle}>
            {renderSeparatorStroke()}
            {!hideText ? (
                <span className="separator-text">{text}</span>
            ) : null}
            {renderSeparatorStroke()}
        </div>
    );
}

Separator.propTypes = {
    text: PropTypes.string,
    hideText: PropTypes.bool,
    fullWidth: PropTypes.bool,
    containerStyle: PropTypes.object,
};

Separator.defaultProps = {
    text: 'OR',
    hideText: false,
    fullWidth: false,
    containerStyle: {},
};

export default Separator;
