import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import TimeSlot from '../TimeSlot';
import './slotDisplay.scss';

const groupConfig = {
    morning: {
        label: 'Morning',
    },
    afternoon: {
        label: 'Afternoon',
    },
    evening: {
        label: 'Evening',
    },
};

const SlotDisplay = ({ slots, onSelectSlot }) => {
    const { slotGroups, totalSlots, preSelectedSlot } = slots;

    const firstGroupAndSlot = useMemo(() => {
        if (preSelectedSlot) {
            return {
                group: preSelectedSlot.group,
                slot: preSelectedSlot.slot,
            };
        }

        if (!isEmpty(slotGroups.morning)) {
            return {
                group: 'morning',
                slot: slotGroups.morning[0],
            };
        }
        if (!isEmpty(slotGroups.afternoon)) {
            return {
                group: 'afternoon',
                slot: slotGroups.afternoon[0],
            };
        }
        return {
            group: 'evening',
            slot: slotGroups.evening[0],
        };
    }, [slots]);

    const [selectedSlotGroup, setSelectedSlotGroup] = useState(firstGroupAndSlot.group);
    const [selectedSlot, setSelectedSlot] = useState(firstGroupAndSlot.slot);

    const handleSelectSlot = (slot) => {
        setSelectedSlot(slot);
        onSelectSlot(slot);
    };

    const handleSelectGroup = (group) => {
        setSelectedSlotGroup(group);
        handleSelectSlot(slotGroups[group][0]);
    };

    const renderGroups = () => {
        if (totalSlots < 12) {
            return null;
        }

        const slotGroupsContent = Object.entries(slotGroups).map(([group, groupSlots]) => (
            <TimeSlot
                key={group}
                label={groupConfig[group].label}
                disabled={groupSlots.length === 0}
                selected={group === selectedSlotGroup}
                onClick={() => { handleSelectGroup(group); }} />
        ));

        return (
            <div className="group-container">
                <p>Please select the time of day:</p>
                <div className="slot-container">
                    {slotGroupsContent}
                </div>
            </div>
        );
    };

    const renderSlots = () => {
        const slotsContent = slotGroups[selectedSlotGroup].filter((slot) => slot.isAvailable).map((slot) => (
            <TimeSlot
                key={slot.startTime}
                label={slot.startTime}
                selected={slot === selectedSlot}
                onClick={() => { handleSelectSlot(slot); }} />
        ));

        return (
            <div className="group-container">
                <p>Please select the time slot:</p>
                <div className="slot-container">
                    {slotsContent}
                </div>
            </div>
        );
    };

    useEffect(() => {
        onSelectSlot(selectedSlot);
    }, [slots]);

    return (
        <>
            {renderGroups()}
            {renderSlots()}
        </>
    );
};

export default SlotDisplay;

SlotDisplay.propTypes = {
    slots: PropTypes.shape({
        slotGroups: PropTypes.shape({
            morning: PropTypes.array,
            afternoon: PropTypes.array,
            evening: PropTypes.array,
        }),
        totalSlots: PropTypes.number,
        preSelectedSlot: PropTypes.shape({
            group: PropTypes.string,
            slot: PropTypes.object,
        }),
    }),
    onSelectSlot: PropTypes.func,
};

SlotDisplay.defaultProps = {
    slots: {},
    onSelectSlot: () => {},
};
